
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as api_45keysnsIVfoAq4lMeta } from "/app/apps/merchant/pages/api-keys.vue?macro=true";
import { default as loginu7IEY0B1cEMeta } from "/app/apps/merchant/pages/auth/login.vue?macro=true";
import { default as indexd2HWJscJmqMeta } from "/app/apps/merchant/pages/index.vue?macro=true";
import { default as api_45keysWNISWyOUBYMeta } from "/app/apps/merchant/pages/profile/api-keys.vue?macro=true";
import { default as indexLtqs2xbY3WMeta } from "/app/apps/merchant/pages/profile/index.vue?macro=true";
import { default as securityi19alWpFKZMeta } from "/app/apps/merchant/pages/profile/security.vue?macro=true";
import { default as profileVmPR8sfvx7Meta } from "/app/apps/merchant/pages/profile.vue?macro=true";
import { default as indexGQleIfSEXEMeta } from "/app/apps/merchant/pages/users-management/index.vue?macro=true";
import { default as api_45keysI2XmZX4jzQMeta } from "/app/apps/merchant/pages/workspaces/[id]/api-keys.vue?macro=true";
import { default as index1paApybfDCMeta } from "/app/apps/merchant/pages/workspaces/[id]/index.vue?macro=true";
import { default as withdrawalFJF20Hj29DMeta } from "/app/apps/merchant/pages/workspaces/[id]/withdrawal.vue?macro=true";
import { default as _91id_93jEL2ZzsbtLMeta } from "/app/apps/merchant/pages/workspaces/[id].vue?macro=true";
export default [
  {
    name: "api-keys",
    path: "/api-keys",
    meta: api_45keysnsIVfoAq4lMeta || {},
    component: () => import("/app/apps/merchant/pages/api-keys.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginu7IEY0B1cEMeta || {},
    component: () => import("/app/apps/merchant/pages/auth/login.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexd2HWJscJmqMeta || {},
    component: () => import("/app/apps/merchant/pages/index.vue")
  },
  {
    name: profileVmPR8sfvx7Meta?.name,
    path: "/profile",
    meta: profileVmPR8sfvx7Meta || {},
    component: () => import("/app/apps/merchant/pages/profile.vue"),
    children: [
  {
    name: "profile-api-keys",
    path: "api-keys",
    meta: api_45keysWNISWyOUBYMeta || {},
    component: () => import("/app/apps/merchant/pages/profile/api-keys.vue")
  },
  {
    name: "profile",
    path: "",
    meta: indexLtqs2xbY3WMeta || {},
    component: () => import("/app/apps/merchant/pages/profile/index.vue")
  },
  {
    name: "profile-security",
    path: "security",
    meta: securityi19alWpFKZMeta || {},
    component: () => import("/app/apps/merchant/pages/profile/security.vue")
  }
]
  },
  {
    name: "users-management",
    path: "/users-management",
    meta: indexGQleIfSEXEMeta || {},
    component: () => import("/app/apps/merchant/pages/users-management/index.vue")
  },
  {
    name: _91id_93jEL2ZzsbtLMeta?.name,
    path: "/workspaces/:id()",
    component: () => import("/app/apps/merchant/pages/workspaces/[id].vue"),
    children: [
  {
    name: "workspaces-id-api-keys",
    path: "api-keys",
    component: () => import("/app/apps/merchant/pages/workspaces/[id]/api-keys.vue")
  },
  {
    name: "workspaces-id",
    path: "",
    meta: index1paApybfDCMeta || {},
    component: () => import("/app/apps/merchant/pages/workspaces/[id]/index.vue")
  },
  {
    name: "workspaces-id-withdrawal",
    path: "withdrawal",
    component: () => import("/app/apps/merchant/pages/workspaces/[id]/withdrawal.vue")
  }
]
  }
]