import revive_payload_client_z6qDWosghe from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4._433d07492759466dfb6f875a9f1919b7/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_8ReAXbw8QD from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4._433d07492759466dfb6f875a9f1919b7/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ORGCFYBUG9 from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4._433d07492759466dfb6f875a9f1919b7/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_1LirkeBvtu from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4._433d07492759466dfb6f875a9f1919b7/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_SMWTJf8yPx from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4._433d07492759466dfb6f875a9f1919b7/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_nBfS0IL73R from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4._433d07492759466dfb6f875a9f1919b7/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8FxGFqCXEC from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.30.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/merchant/.nuxt/components.plugin.mjs";
import prefetch_client_92SwNcEpLq from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4._433d07492759466dfb6f875a9f1919b7/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_92bxaweC4d from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.2_magicast@0.3.5_next-auth@4.21.1_next@13.5.8_@babel+core@7.26._b7715fc8908df997c114325e55ba2c5a/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_TqWukcqsFH from "/app/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.7_jiti@2.4._a77478c7b5ec9445001195055f1f21c8/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import paymentApiV1_fc0kMFsFCb from "/app/packages/authLayer/plugins/paymentApiV1.ts";
export default [
  revive_payload_client_z6qDWosghe,
  unhead_8ReAXbw8QD,
  router_ORGCFYBUG9,
  payload_client_1LirkeBvtu,
  navigation_repaint_client_SMWTJf8yPx,
  chunk_reload_client_nBfS0IL73R,
  plugin_vue3_8FxGFqCXEC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_92SwNcEpLq,
  plugin_92bxaweC4d,
  plugin_TqWukcqsFH,
  paymentApiV1_fc0kMFsFCb
]